/*************************************************
 * ByzFunder
 * @exports
 * SessionStorage.ts
 * Created by vinoth kumar on  15/05/2024
 * Copyright © 2024 ByzFunder. All rights reserved.
 *************************************************/

let engine = require("store/src/store-engine");
let storetypes = [require("store/storages/sessionStorage")];

let storeplugins = [require("store/plugins/defaults")];
export default engine.createStore(storetypes, storeplugins);
