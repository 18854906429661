/*************************************************
 * ByzFunder
 * @exports
 * customFetchBase.ts
 * Created by vinoth kumar on  15/05/2024
 * Copyright © 2024 ByzFunder. All rights reserved.
 *************************************************/

// Import necessary modules
import { fetchBaseQuery } from "@reduxjs/toolkit/query";
import { Mutex } from "async-mutex";
import { logout } from "./../slice/UserSlice";
import store from "./../SessionStorage";

// Define base URL for API requests
const baseUrl = process.env.REACT_APP_CUSTOMER_API_BASE_URL;

// Create a mutex to manage concurrent access
const mutex = new Mutex();

// Define base query function with authorization header logic
const baseQuery = fetchBaseQuery({
  baseUrl,
  prepareHeaders: (headers) => {
    headers.set("Accept", "application/json");
    const user = store.get("user")
      ? JSON.parse(store.get("user")!)
      : null;
    if (user) {
      headers.set("authorization", `Bearer ${user?.token}`);
    }
    return headers;
  },
});

// Custom fetch function to intercept and handle requests
const customFetchBase = async (args: any, api: any, extraOptions: any) => {
  // Wait for mutex to unlock
  await mutex.waitForUnlock();
  // Make the request using baseQuery
  let result = await baseQuery(args, api, extraOptions);
  // If request returns Unauthorized (401)
  if (result.error?.status === 401) {
    // If mutex is not locked, acquire it
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      try {
        // Retry request with updated token
        // result = await baseQuery(args, api, extraOptions);
        const user = store.get("user")
          ? JSON.parse(store.get("user")!)
          : null;
        if (user) {
          api.dispatch(logout({}));
          window.location.href = "/";
        }
      } finally {
        // Release mutex
        release();
      }
    } else {
      // If mutex is locked, wait for it to unlock and retry request
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }
  return result;
};

export default customFetchBase;