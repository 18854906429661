/*************************************************
 * ByzFunder
 * @exports
 * Store.ts
 * Created by vinoth kumar on  15/05/2024
 * Copyright © 2024 ByzFunder. All rights reserved.
 *************************************************/
import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./slice/UserSlice";
import { authApi } from "./services/AuthApi";

export const Store = configureStore({
  // Define reducers for the store
  reducer: {
    userState: userReducer,
    [authApi.reducerPath]: authApi.reducer,
  },
  // Enable Redux DevTools extension only in development mode
  devTools: process.env.NODE_ENV === "development",
  // Add middleware functions for handling asynchronous actions
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({}).concat([authApi.middleware]),
});

// Define type for the root state of the store
export type RootState = ReturnType<typeof Store.getState>;
// Define type for the dispatch function of the store
export type AppDispatch = typeof Store.dispatch;
