/*************************************************
 * ByzFunder
 * @exports
 * UserSlcice.ts
 * Created by vinoth kumar on  15/05/2024
 * Copyright © 2024 ByzFunder. All rights reserved.
 *************************************************/

// Importing necessary modules
import { createSlice } from "@reduxjs/toolkit";
import store from "./../SessionStorage";

// Initial state for the user slice, fetched from localStorage if available
const initialState = {
  user: store.get("user") ? JSON.parse(store.get("user") || "") : null,
};

// Creating a Redux slice for managing user state
export const UserSlice = createSlice({
  initialState, // Initial state of the slice
  name: "user", // Name of the slice
  reducers: {
    // Reducer function to set user data
    setUser: (state, action) => {
      // Update localStorage with the provided user data
      store.set("user", JSON.stringify(action.payload));
      // Update the user state in the Redux store
      state.user = action.payload;
    },
    setImpersonateUser: () => {
      store.set("impersonate", "true");
    },
    // Reducer function to clear user data
    logout: (state, action) => {
      store.remove("user");
      store.remove("impersonate");
      return { user: null };
    },
  },
});

// Exporting the reducer function generated by createSlice
export default UserSlice.reducer;

// Exporting the setUser action creator for dispatching actions to set user data
export const { setUser, setImpersonateUser, logout } = UserSlice.actions;

// Selector function to retrieve the current user from the Redux state
export const currentUser = (state: any) => state.userState.user;
