/*************************************************
 * ByzFunder
 * @exports
 * App.tsx
 * Created by vinoth kumar on 08/05/2024
 * Copyright © 2024 ByzFunder. All rights reserved.
 *************************************************/
import React from "react";
import Routerconfig from "./routes";
import CookieConsent from "react-cookie-consent";

function App() {
  return (
    <React.Fragment>
      <CookieConsent
        location="bottom"
        buttonText="I agree"
        cookieName="myAppCookieConsent"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
      >
        This website uses cookies to enhance the user experience.
      </CookieConsent>
      <Routerconfig />
    </React.Fragment>
  );
}

export default App;
