/*************************************************
* ByzFunder
 * @exports
 * index.tsx
 * Created by vinoth kumar on 08/05/2024
 * Copyright © 2024 ByzFunder. All rights reserved.
 *************************************************/
// Import necessary modules
import { lazy, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Loading from './../components/pages/common/Loading';
import store from "./../redux/SessionStorage";

// Define a reusable Suspense wrapper component
const Loadable = (Component: any) => (props: any) => (
	<Suspense fallback={<Loading />}>
		<Component {...props} />
	</Suspense>
);

// Define your route components lazily
const Login = Loadable(lazy(() => import("../components/pages/Login")));
const ForgotPassword = Loadable(lazy(() => import("../components/pages/ForgotPassword")));
const ResetPassword = Loadable(lazy(() => import("../components/pages/ResetPassword")));
const Signup = Loadable(lazy(() => import("../components/pages/Signup")));
const SetPassword = Loadable(lazy(() => import("../components/pages/SetPassword")));
const OtpVerification = Loadable(lazy(() => import("../components/pages/OtpVerification")));
const Dashboard = Loadable(lazy(() => import("../components/pages/Dashboard")));
const ErrorPage = Loadable(lazy(() => import("../components/pages/ErrorPage")));

const PrivateRoute = ({ children }: any) => {
	const user = store.get("user")
		? JSON.parse(store.get("user") || "")
		: null;
	if (!user) {
		return <Navigate to="/login" />;
	}
	return children;
};
// Define your route configuration within the context of Router
export default function AppRouter() {
	return (
		<Router>
			<Routes>
				<Route path="/login" element={<Login />} />
				<Route path="/forgot-password" element={<ForgotPassword />} />
				<Route path="/reset-password" element={<ResetPassword />} />
				<Route path="/signup" element={<Signup />} />
				<Route path="/set-password" element={<SetPassword />} />
				<Route path="/otp-verify" element={<OtpVerification />} />
				<Route path="/error" element={<ErrorPage />} />
				<Route path="/dashboard"
					element={
						<PrivateRoute>
							<Dashboard />
						</PrivateRoute>
					} />
				<Route path="/" element={<Navigate to="/login" />} />
				<Route path="*" element={<Navigate to="/login" />} />

			</Routes>
		</Router>
	);
}
