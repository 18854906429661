/*************************************************
 * ByzFunder
 * @exports
 * AuthApi.ts
 * Created by vinoth kumar on  15/05/2024
 * Copyright © 2024 ByzFunder. All rights reserved.
 *************************************************/

import { createApi } from "@reduxjs/toolkit/query/react";
import CustomFetchBase from "./CustomFetchBase";
import { setUser, setImpersonateUser } from "../slice/UserSlice";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: CustomFetchBase,
  endpoints: (builder) => ({
    loginUser: builder.mutation({
      query: (body) => ({
        url: `login`,
        method: "POST",
        body,
      }),
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          if (data.data?.users?.is_verified) {
            dispatch(setUser(data.data?.users));
          }
        } catch (error) { }
      },
    }),
    googleAuth: builder.mutation({
      query: (body) => ({
        url: `google-auth`,
        method: "POST",
        body,
      }),
    }),
    logoutUser: builder.mutation({
      query: (body) => ({
        url: `logout`,
        method: "POST",
        body,
      }),
    }),
    forgotPassword: builder.mutation({
      query: (body) => ({
        url: `send-forgot-password-otp`,
        method: "POST",
        body,
      }),
    }),
    changePassword: builder.mutation({
      query: (body) => ({
        url: `rest-password`,
        method: "POST",
        body,
      }),
    }),
    registerUser: builder.mutation({
      query: (body) => ({
        url: `register`,
        method: "POST",
        body,
      }),
    }),
    lookupUser: builder.mutation({
      query: (body) => ({
        url: `lookup-user`,
        method: "POST",
        body,
      }),
    }),
    verifyEmail: builder.mutation({
      query: (body) => ({
        url: `verify-email`,
        method: "POST",
        body,
      }),
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          if (data.data?.users?.is_verified) {
            dispatch(setUser(data.data?.users));
          }
        } catch (error) { }
      },
    }),
    rensendVerifyEmail: builder.mutation({
      query: (body) => ({
        url: `resend-verify-email`,
        method: "POST",
        body,
      }),
    }),
    getDashboardDetails: builder.query({
      query: () => ({
        url: `dashboard`,
        method: "GET",
      }),
    }),
    requestPayoutLater: builder.mutation({
      query: (body) => ({
        url: `request-payoff-letter`,
        method: "POST",
        body,
      }),
    }),
    viewStatement: builder.mutation({
      query: (body) => ({
        url: `view-statement`,
        method: "POST",
        body,
      }),
    }),
    requestFunding: builder.mutation({
      query: (body) => ({
        url: `request-funding`,
        method: "POST",
        body,
      }),
    }),
    getImpersonateUser: builder.query({
      query: (params) => ({
        url: `impersonate-signin`,
        method: "GET",
        params,
      }),
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          if (data.data?.users?.is_verified) {
            dispatch(setUser(data.data?.users));
            dispatch(setImpersonateUser());

          }
        } catch (error) { }
      },
    }),
  }),
});

export const {
  useLoginUserMutation,
  useGoogleAuthMutation,
  useLogoutUserMutation,
  useForgotPasswordMutation,
  useChangePasswordMutation,
  useRegisterUserMutation,
  useLookupUserMutation,
  useVerifyEmailMutation,
  useRensendVerifyEmailMutation,
  useLazyGetDashboardDetailsQuery,
  useViewStatementMutation,
  useRequestPayoutLaterMutation,
  useRequestFundingMutation,
  useLazyGetImpersonateUserQuery
} = authApi;
